<template>
  <div>
    <topbar
      :titulo="$t('ACADEMICO.FORMULARIO_ADITIVO')"
      :subtitulo="$t('ACADEMICO.FORMULARIO_ADITIVO_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    >
      <template #header>
        <b-button
          variant="secondary"
          class="mr-2"
          @click="retornarParaAditivos"
        >
          {{ $t('GERAL.VOLTAR') }}
        </b-button>
      </template>
    </topbar>
    <b-tabs v-model="tabIndex" card>
      <b-tab :title="`1 - ${$t('ACADEMICO.DADOS_GERAIS_DO_ADITIVO')}`">
        <b-row>
          <b-col cols="6">
            <input-text
              v-model="contratoSelcionado.aluno"
              disabled
              :label="$t(`ACADEMICO.ALUNO`)"
            />
          </b-col>
          <b-col cols="6">
            <input-text
              v-model="contratoSelcionado.codigoContrato"
              :label="$t(`ACADEMICO.CODIGO`)"
              disabled
            />
          </b-col>
          <b-col cols="12">
            <input-select-search
              ref="tiposAditivo"
              :label="$t(`ACADEMICO.TIPO_ADITIVO`)"
              :options="opcoes.tipoAditivos"
              v-model="form.tipoAditivoContrato"
              required
            />
          </b-col>
          <b-col cols="12">
            <input-editor-text
              ref="motivo"
              :label="$t(`ACADEMICO.DETALHES_DO_MOTIVO`)"
              :value="form.motivo"
              v-model="form.motivo"
              required
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`2 - ${$t('ACADEMICO.PRECOS')}`">
        <b-row>
          <b-col cols="12">
            <tabela-precos :form="form" @atualizou-preco="recarregarParcelas" />
          </b-col>

          <b-col cols="12">
            <tabela-valores-plano-parcelamento :itens="planoParcelamentos" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`3 - ${$t('ACADEMICO.DESCONTOS')}`">
        <tabela-descontos-adicionados :form="form" />
        <div class="d-flex justify-content-end">
          <b-button variant="primary" @click="salvarAditivo()">{{
            $t('ACADEMICO.SALVAR_ADITIVO')
          }}</b-button>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// AUX & HELPERS
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import rotas from '@/common/utils/rotas';

// SERVICES
import PrecoService from '@/common/services/precos/preco.service';
import ContratosService from '@/common/services/contratos/contratos.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

// COMPONENTS
import {
  InputSelectSearch,
  InputEditorText,
  InputText,
} from '@/components/inputs';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import TabelaPrecos from '@/views/academico/aditivo-contratos/components/tabelas/TabelaPrecosAdicionados';
import TabelaValoresPlanoParcelamento from '@/views/academico/aditivo-contratos/components/tabelas/TabelaValoresPlanoParcelamento';
import TabelaDescontosAdicionados from '@/views/academico/aditivo-contratos/components/tabelas/TabelaDescontosAdicionados';

export default {
  name: 'FormularioCriarAditivo',

  props: {
    exibir: { type: Boolean, default: false },
  },

  components: {
    Topbar,
    InputSelectSearch,
    InputEditorText,
    InputText,
    TabelaPrecos,
    TabelaValoresPlanoParcelamento,
    TabelaDescontosAdicionados,
  },

  data() {
    return {
      opcoes: {
        tipoAditivos: [],
      },
      planoParcelamentos: [],
      contratoSelcionado: {
        aluno: null,
        codigoContrato: null,
      },
      form: {
        contratos: [this.$route.params.contratoId],
        tipoAditivoContrato: '',
        precos: [],
        descontos: [],
        motivo: '',
        dataGeracao: new Date(),
      },
      tabIndex: 0,
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.CONTRATOS') },
        { titulo: this.$t('ACADEMICO.FORMULARIO_ADITIVO') },
      ],
    };
  },

  watch: {
    'form.precos': {
      handler(precos) {
        const precoIds = precos.map((item) => ({
          precoId: item.id,
          formaPagamentoId: item.formaPagamentoId,
        }));
        if (precoIds.length > 0) return this.getPlanosParcelamento(precoIds);
        this.planoParcelamentos = [];
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.pegarTiposAditivo();
    this.pegarContratoPorId();
  },

  methods: {
    salvarAditivo() {
      if (!this.validarFormulario())
        return mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t('ACADEMICO.PREENCHA_OS_CAMPOS_OBRIGATORIOS')
        );

      if (this.form.precos.length === 0 && this.form.descontos.length === 0) {
        return mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t('ACADEMICO.PREENCHA_PRECOS_OU_DESCONTOS')
        );
      }
      const descontos = this.form.descontos.map((desconto) => desconto.id);
      const formFormatado = { ...this.form, descontos };
      this.$store.dispatch(START_LOADING);
      ContratosService.adicionarAditivoContratos(formFormatado)
        .then(({ data }) => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), this.$t(data.mensagem));
          this.form = {
            contratos: [this.$route.params.contratoId],
            tipoAditivoContrato: '',
            precos: [],
            descontos: [],
            motivo: ' ',
            dataGeracao: new Date(),
          };
          this.retornarParaAditivos();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    pegarContratoPorId() {
      this.$store.dispatch(START_LOADING);
      ContratosService.buscarContratoPorMatriculaId(
        this.$route.params.matriculaId
      )
        .then(({ data }) => {
          if (data.itens.length) {
            data.itens.forEach((item) => {
              if (item.id == this.$route.params.contratoId) {
                this.contratoSelcionado = item;
              }
            });
            this.form.descontos = this.contratoSelcionado?.descontos ?? [];
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    pegarTiposAditivo() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipos-aditivos-contrato')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoAditivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    recarregarParcelas() {
      this.getPlanosParcelamento(this.form.precos);
    },

    getPlanosParcelamento(listaPrecosIds) {
      this.$store.dispatch(START_LOADING);
      PrecoService.buscarPlanosPagamento(listaPrecosIds)
        .then(({ data }) => {
          this.planoParcelamentos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    retornarParaAditivos() {
      rotas.irPara('contratos', this.$route.params.contratoId);
    },

    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
